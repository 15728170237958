.dashboard-head {
  color: #21CD9A;
  font-weight: 600;
  font-size: 24px;
}
.users {
  margin-top: 80px;
}
.user-loans {
  color: #525252;
  font-size: 14px;
}
.user-week {
  color: #131212;
  font-weight: 700;
  font-size: 12px;
}
.card-height {
  min-height: 200px;
  border-radius: 8px !important;
  box-shadow: 0px 0px 4px 0px #0000001F !important;
}
.loan-statics {
  color: #131212;
  font-size: 14px;
  font-weight: 700;
}
.loan-disbursed {
  color: #21CD9A;
  font-size: 12px;
  font-weight: 700px;
}

.card-line-height {
  height: 554px;
  border-radius: 8px !important;
  box-shadow: 0px 0px 4px 0px #0000001F !important;
}