.card-main {
  border-radius: 8px !important;
  box-shadow: 0px 0px 4px 0px #0000001F !important;
}
.users {
  margin-top: 80px;
}
.user-head {
  font-size: 14px;
  font-weight: 600;
  color: #8C8282;
  padding-top: 10px;
}
.user-loans {
  color: #525252;
  font-size: 14px;
}

.user-number {
  color: #21CD9A;
  font-size: 24px;
  font-weight: 800;
}

.image-icon {
  width: 22.5px;
}

.card-height {
  min-height: 200px;
  border-radius: 8px !important;
  box-shadow: 0px 0px 4px 0px #0000001F !important;
}

.profile-image {
  height: 400px;
  width: 100%;
  border-radius: 12px !important;
  object-fit: cover;
}

.user-info {
  color: #CACACA;
  font-weight: 600;
  font-size: 14px;
}

.input-form {
  background: #fafafa !important;
  border: none !important;
}

.card-line-height {
  height: 554px;
  border-radius: 8px !important;
  box-shadow: 0px 0px 4px 0px #0000001F !important;
}

.display-name {
  color: #00BF7A;
}

.tab-head {
  font-size: 14px;
  font-weight: 600;
}

.react-tabs__tab--selected {
  color: #ff9900 !important;
}

.view {
  background: #21CD9A;
  color: #ffffff;
  border: none;
  padding: 7px 28px;
  border-radius: 4px;
}

.table-row:hover {
  cursor: pointer;
}
.nav-pills .nav-link.active {
  background: #ff9900 !important;
}