.skeleton {
  animation: skeleton-loading 1s linear infinite alternate; 
  opacity: .7;
}

.skeleton-search {
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.skeleton-search:last-child {
  margin-bottom: 0;
  width: 80%;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(199, 18%, 83%);
  }
  100% {
    background-color: hsl(200, 11%, 95%);
  }
}